<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col>
        <template>
          <v-card>
            <v-card-title>
              <v-col cols="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                />
              </v-col>
            </v-card-title>

            <v-responsive class="overflow-y-auto" max-height="calc(90vh - 250px)">
              <v-data-table
                :headers="headers"
                :items="paquetes"
                :search="search"
                :items-per-page="10"
                dense
                mobile-breakpoint
                class="elevation-2"
              >
                <template v-slot:item.accion="{ item }">
                  <v-icon medium color="black" @click="handlerClick(item)">mdi-eye</v-icon>
                  <v-icon medium color="black" @click="eliminarModal(item)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-responsive>
          </v-card>

          <v-dialog v-model="dialog">
            <v-card>
              <v-card-text>
                <base-material-card color="drawerColorSecondary">
                  <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                      <v-spacer></v-spacer>
                      {{paquete.nombre}}
                    </div>
                    <div class="subtitle-1 font-weight-light">{{paquete.nombreCuenta}}</div>
                  </template>

                  <v-card>
                    <v-data-table
                      :headers="headersMateriales"
                      :items="paqueteMateriales"
                      :items-per-page="5"
                      fixed-header
                      dense
                      class="elevation-2"
                    />
                  </v-card>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="verdeBoton" @click="dialog = false">Cerrar</v-btn>
                  </v-card-actions>
                </base-material-card>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogEliminar" persistent max-width="340">
      <v-card>
        <v-card-title class="headline">
          ¿Quiere elimnar el paquete con la clave
          <b>{{item.idPaquete}}</b>?
        </v-card-title>
        <v-card-text class="text-center">Al dar aceptar se haran los cambios en el sistema</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="verdeBoton darken-1"
            text
            @click="dialogEliminar = !dialogEliminar"
            v-text="$t('cancelar')"
          />
          <v-btn color="verdeBoton darken-1" text @click="deleteItem()" v-text="$t('aceptar')" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "paquetesPublicos",
  data() {
    return {
      dialog: false,
      dialogEliminar: false,
      search: "",
      headers: [
        { text: "Clave", value: "idPaquete" },
        { text: "Nombre de paquete", value: "nombre" },
        { text: "Fecha", value: "fecha" },
        { text: "Nombre de usuario", value: "nombreCuenta" },
        { text: "Tipo de usuario", value: "tipoUsuario" },
        { text: "Acciones", value: "accion", sortable: false, width: "10%" },
      ],
      headersMateriales: [
        { text: "Material", value: "nombre" },
        { text: "Descripcion", value: "descripcion" },
        { text: "potenciador", value: "potenciador" },
        { text: "Unidad de medida", value: "unidadM" },
      ],
      paquetes: [],
      paqueteMateriales: [],
      paquete: {},
      item: {},
    };
  },
  mounted() {
    fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/paquetes", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.cuenta.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((paquetes) => {
        this.paquetes = paquetes;
      });
  },
  methods: {
    eliminarModal(item) {
      this.dialogEliminar = !this.dialogEliminar;
      this.item = item;
    },
    /**
     *
     */
    deleteItem() {
      fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/paquete", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.cuenta.token,
        },
        body: JSON.stringify({
          idPaquete: this.item.idPaquete,
        }),
      }).then((response) => {
        const posicion = this.paquetes.indexOf(this.item);
        this.dialogEliminar = !this.dialogEliminar;
        this.paquetes.splice(posicion, 1);
      });
    },
    /**
     * Metodo para escuchar un click en la tabla y se hace un http GET para obtener informacion del paquete
     */
    handlerClick(item) {
      fetch(
        process.env.VUE_APP_URL_BACKEND +
          "/administrador/paquetes/" +
          item.idPaquete,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.state.cuenta.token,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((materiales) => {
          this.paqueteMateriales = materiales;
          this.paquete = item;
          this.dialog = !this.dialog;
        });
    },
  },
};
</script>
